import { SVGProps, Ref, forwardRef } from 'react'

const SvgInstagram = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={2.16}
      d="M11.879 16.32a4.32 4.32 0 1 0 0-8.64 4.32 4.32 0 0 0 0 8.64Z"
    />
    <path
      stroke="currentColor"
      strokeWidth={2.16}
      d="M15.118 2.28h-6.48a6.48 6.48 0 0 0-6.48 6.48v6.48a6.48 6.48 0 0 0 6.48 6.48h6.48a6.48 6.48 0 0 0 6.48-6.48V8.76a6.48 6.48 0 0 0-6.48-6.48Z"
    />
    <path fill="currentColor" d="M17.278 7.8a1.2 1.2 0 1 0 0-2.4 1.2 1.2 0 0 0 0 2.4Z" />
  </svg>
)

const ForwardRef = forwardRef(SvgInstagram)
export default ForwardRef
